<template>
  <el-drawer
      title="选择商品SKU"
      :visible.sync="drawer"
      :direction="direction"
      append-to-body
      :before-close="handleClose">
    <div class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
      <el-checkbox-group v-model="checkedSKU" @change="handleCheckedSKUChange">
        <el-card class="box-card" v-for="item in listData" :key="item.Id">
          <el-checkbox :label="item.Id">
            <div class="item u-start-flex">
              <div style="width: 30rem">SKU编码：{{ item.SKUCode }}</div>
              <div>{{ item.Name }}</div>
            </div>
            <div class="item u-start-flex">
              <div style="width: 30rem">商品品类：{{ item.ProductName }}</div>
              <div>{{ item.GoodsTypeName }}</div>
            </div>
          </el-checkbox>
        </el-card>
      </el-checkbox-group>
    </div>
    <div style="padding: 0 20px" class="u-between-flex">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
        全选 已选中：{{ checkedSKU.length }}
      </el-checkbox>
      <el-button @click="submitCheckBox" type="primary" size="small">提交</el-button>
    </div>
  </el-drawer>
</template>

<script>
import {getCodSKU} from "@/api/cod";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    SKUIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      direction: 'rtl',
      checkedSKU: [],
      listData: [],
      checkAll: false,
      isIndeterminate: true
    }
  },
  watch: {
    SKUIds: {
      handler() {
        this.load()
      },
      immediate: true
    }
  },
  methods: {
    handleCheckedSKUChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listData.length;
    },
    handleCheckAllChange(val) {
      this.checkedSKU = val ? this.listData.map(item => { return item.Id }) : [];
      this.isIndeterminate = false
    },
    handleClose() {
      this.$emit('close')
    },
    submitCheckBox() {
      
    },
    load () {
      const data = {
        PageIndex: 1,
        PageRows: 99,
      }
      getCodSKU(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data
          Data.forEach(item => {
            this.$set(this.expands, item.SKUCode, false)
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
.infinite-list {
  width: 95%;
  height: 70rem;
  margin: 0 auto;
  .box-card {
    margin: 10px 0;
    .item {
      font-size: 12px;
    }
  }
}
</style>